// Generated by BUCKLESCRIPT VERSION 4.0.5, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Random from "bs-platform/lib/es6/random.js";
import * as Bonus$StronglyTyped from "./bonus.bs.js";
import * as Common$StronglyTyped from "./common.bs.js";

var words = /* :: */[
  "Messaging",
  /* :: */[
    "Logging",
    /* :: */[
      "Memory Store",
      /* :: */[
        "postgresql",
        /* :: */[
          "kubernetes",
          /* :: */[
            "terraform",
            /* :: */[
              "mysql",
              /* :: */[
                "serverless",
                /* :: */[
                  "containerization",
                  /* :: */[
                    "scalability",
                    /* :: */[
                      "Redis",
                      /* :: */[
                        "RabbitMQ",
                        /* :: */[
                          "machine learning",
                          /* :: */[
                            "analytics",
                            /* :: */[
                              "Optimization",
                              /* :: */[
                                "CMS",
                                /* :: */[
                                  "Elastic",
                                  /* :: */[
                                    "Algolia",
                                    /* :: */[
                                      "Jaws",
                                      /* :: */[
                                        "Timber",
                                        /* :: */[
                                          "Iron",
                                          /* :: */[
                                            "Piio",
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

function spawn(ui) {
  var word = List.nth(words, Random.$$int(List.length(words) - 1 | 0));
  var max = ui[/* width */1] - Curry._1(ui[/* calculateWidth */3], word);
  return /* record */[
          /* text */word,
          /* velocity */1.5 + Random.$$float(2.5),
          /* x */Random.$$float(max),
          /* y */0.0
        ];
}

function bonusCaptured(state) {
  return /* record */[
          /* gameOver */state[/* gameOver */0],
          /* words : [] */0,
          /* captured */state[/* words */1],
          /* crashed : [] */0,
          /* ticks */state[/* ticks */4],
          /* base */state[/* base */5],
          /* crashCollector */state[/* crashCollector */6],
          /* bonus */undefined,
          /* clear */true
        ];
}

function collect(state, ui) {
  var match = List.partition((function (w) {
          return w[/* text */0] === Curry._1(ui[/* input */2], /* () */0);
        }), state[/* words */1]);
  var captured = match[0];
  var match$1 = List.partition((function (w) {
          return w[/* y */3] > ui[/* height */0];
        }), match[1]);
  var match$2 = state[/* base */5];
  var crashed = List.filter((function (word) {
            var left = word[/* x */2];
            var right = word[/* x */2] + Curry._1(ui[/* calculateWidth */3], word[/* text */0]);
            return Curry._1(state[/* crashCollector */6][/* crash */0], /* record */[
                        /* left */left,
                        /* right */right
                      ]);
          }))(match$1[0]);
  var newWords = List.map((function (word) {
          return /* record */[
                  /* text */word[/* text */0],
                  /* velocity */word[/* velocity */1],
                  /* x */word[/* x */2],
                  /* y */word[/* y */3] + word[/* velocity */1]
                ];
        }), match$1[1]);
  var match$3 = state[/* ticks */4] % 70;
  var newWords$1 = match$3 !== 0 ? newWords : List.append(newWords, /* :: */[
          spawn(ui),
          /* [] */0
        ]);
  var gameOver = Curry._2(state[/* crashCollector */6][/* covers */1], match$2[0], match$2[1]);
  var match$4 = state[/* bonus */7];
  var newBonus;
  if (match$4 !== undefined) {
    var bonus = match$4;
    newBonus = bonus[/* x */0] === ui[/* width */1] ? undefined : Bonus$StronglyTyped.tick(bonus, ui);
  } else {
    var match$5 = Random.$$float(1.0) < 0.0015;
    newBonus = match$5 ? Bonus$StronglyTyped.spawn(/* () */0) : undefined;
  }
  var matchesBonus = newBonus !== undefined ? Common$StronglyTyped.startsWith(Common$StronglyTyped.bonusWord, Curry._1(ui[/* input */2], /* () */0)) : false;
  var partial_arg = Curry._1(ui[/* input */2], /* () */0);
  var matchesWord = List.exists((function (param) {
          return Common$StronglyTyped.isPrefixOf(partial_arg, param);
        }), newWords$1);
  var clear = List.length(captured) > 0 || !(matchesBonus || matchesWord);
  return /* record */[
          /* gameOver */gameOver,
          /* words */newWords$1,
          /* captured */captured,
          /* crashed */crashed,
          /* ticks */state[/* ticks */4] + 1 | 0,
          /* base */state[/* base */5],
          /* crashCollector */state[/* crashCollector */6],
          /* bonus */newBonus,
          /* clear */clear
        ];
}

function nextState(state, ui) {
  if (state[/* gameOver */0]) {
    return state;
  } else {
    var match = state[/* bonus */7];
    if (match !== undefined && Curry._1(ui[/* input */2], /* () */0) === Common$StronglyTyped.bonusWord) {
      return bonusCaptured(state);
    } else {
      return collect(state, ui);
    }
  }
}

export {
  words ,
  spawn ,
  bonusCaptured ,
  collect ,
  nextState ,
  
}
/* Bonus-StronglyTyped Not a pure module */
