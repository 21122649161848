// Generated by BUCKLESCRIPT VERSION 4.0.5, PLEASE EDIT WITH CARE


function startsWith(str, input) {
  return new RegExp("^" + input).test(str);
}

function isPrefixOf(input, word) {
  return startsWith(word[/* text */0], input);
}

var bonusWord = "manifold";

export {
  startsWith ,
  isPrefixOf ,
  bonusWord ,
  
}
/* No side effect */
