// Generated by BUCKLESCRIPT VERSION 4.0.5, PLEASE EDIT WITH CARE


var tau = 2.0 * Math.PI;

function tick(bonus, ui) {
  var match = bonus[/* x */0] === ui[/* width */1];
  return /* record */[
          /* x */match ? 0.0 : bonus[/* x */0] + 1.0,
          /* startY */bonus[/* startY */1],
          /* offsetY */bonus[/* amplitude */3] * Math.sin(tau * 0.02 * bonus[/* x */0]),
          /* amplitude */bonus[/* amplitude */3]
        ];
}

function spawn() {
  return /* record */[
          /* x */0.0,
          /* startY */60.0,
          /* offsetY */0.0,
          /* amplitude */30
        ];
}

export {
  tau ,
  tick ,
  spawn ,
  
}
/* tau Not a pure module */
