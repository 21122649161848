// Generated by BUCKLESCRIPT VERSION 4.0.5, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Caml_primitive from "bs-platform/lib/es6/caml_primitive.js";
import * as Caml_builtin_exceptions from "bs-platform/lib/es6/caml_builtin_exceptions.js";

function connectRight(a, b) {
  return /* record */[
          /* left */a[/* left */0],
          /* right */Caml_primitive.caml_float_max(a[/* right */1], b[/* right */1])
        ];
}

function crashSite(left, right) {
  var sites = /* record */[/* contents : [] */0];
  var covers = function (x, y) {
    return List.exists((function (site) {
                  if (site[/* left */0] <= x) {
                    return site[/* right */1] >= y;
                  } else {
                    return false;
                  }
                }), sites[0]);
  };
  var insert = function (site) {
    var match = List.partition((function (s) {
            return s[/* left */0] <= site[/* left */0];
          }), sites[0]);
    var rightSide = match[1];
    var leftSide = match[0];
    var match$1 = List.partition((function (s) {
            return s[/* left */0] <= site[/* right */1];
          }), rightSide);
    var overlap = match$1[0];
    var rightSide$1;
    if (overlap) {
      var closest = List.hd(List.rev(overlap));
      rightSide$1 = /* :: */[
        connectRight(site, closest),
        match$1[1]
      ];
    } else {
      rightSide$1 = /* :: */[
        site,
        rightSide
      ];
    }
    var site$1 = List.hd(rightSide$1);
    var match$2 = List.rev(leftSide);
    var leftSide$1;
    if (match$2) {
      var s = match$2[0];
      leftSide$1 = s[/* right */1] >= site$1[/* left */0] ? List.rev(/* :: */[
              connectRight(s, site$1),
              match$2[1]
            ]) : List.append(leftSide, /* :: */[
              site$1,
              /* [] */0
            ]);
    } else {
      leftSide$1 = List.append(leftSide, /* :: */[
            site$1,
            /* [] */0
          ]);
    }
    var tmp;
    var exit = 0;
    var tail;
    try {
      tail = List.tl(rightSide$1);
      exit = 1;
    }
    catch (raw_exn){
      var exn = Js_exn.internalToOCamlException(raw_exn);
      if (exn[0] === Caml_builtin_exceptions.failure) {
        tmp = leftSide$1;
      } else {
        throw exn;
      }
    }
    if (exit === 1) {
      tmp = List.append(leftSide$1, tail);
    }
    sites[0] = tmp;
    return /* () */0;
  };
  var crash = function (site) {
    var site_000 = /* left */Caml_primitive.caml_float_max(site[/* left */0], left);
    var site_001 = /* right */Caml_primitive.caml_float_min(site[/* right */1], right);
    var site$1 = /* record */[
      site_000,
      site_001
    ];
    if (covers(site_000, site_001)) {
      return false;
    } else {
      insert(site$1);
      return true;
    }
  };
  var percentCovered = function (x, y) {
    var x$1 = x < y ? x : y;
    var y$1 = x > y ? x : y;
    var amountCovered = List.fold_left((function (sum, site) {
            return sum + (Caml_primitive.caml_float_min(site[/* right */1], y$1) - Caml_primitive.caml_float_max(site[/* left */0], x$1));
          }), 0.0, sites[0]);
    return 100.0 * amountCovered / (y$1 - x$1);
  };
  return /* record */[
          /* crash */crash,
          /* covers */covers,
          /* percentCovered */percentCovered,
          /* sites */(function () {
              return sites[0];
            })
        ];
}

export {
  connectRight ,
  crashSite ,
  
}
/* No side effect */
