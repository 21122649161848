// Generated by BUCKLESCRIPT VERSION 4.0.5, PLEASE EDIT WITH CARE

import * as Random from "bs-platform/lib/es6/random.js";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Js_primitive from "bs-platform/lib/es6/js_primitive.js";
import * as App$StronglyTyped from "./app.bs.js";

var match = module.hot;

if (match !== undefined) {
  Js_primitive.valFromOption(match).accept((function () {
          console.log("sooooo hot right now");
          return /* () */0;
        }));
}

Random.self_init(/* () */0);

ReactDOMRe.renderToElementWithId(ReasonReact.element(undefined, undefined, App$StronglyTyped.make(/* array */[])), "app");

export {
  
}
/* match Not a pure module */
