// Generated by BUCKLESCRIPT VERSION 4.0.5, PLEASE EDIT WITH CARE


function audioContext() {
  try {
    return new AudioContext();
  }
  catch (exn){
    return new webkitAudioContext();
  }
}

function loadSound(ctx, filename) {
  return new Promise((function (resolve, _) {
                var req = new XMLHttpRequest();
                req.open("GET", filename, true);
                req.responseType = "arraybuffer";
                req.onload = (function () {
                    ctx.decodeAudioData(req.response, (function (buffer) {
                            return resolve(buffer);
                          }));
                    return /* () */0;
                  });
                req.send();
                return /* () */0;
              }));
}

function playSound(ctx, buffer) {
  var source = ctx.createBufferSource();
  source.buffer = buffer;
  source.connect(ctx.destination);
  source.start(0);
  return /* () */0;
}

export {
  audioContext ,
  loadSound ,
  playSound ,
  
}
/* No side effect */
